<template>
    <ProductList v-model:productListVisible="productListVisible" @onSelect="onSelectProduct" />
    <RouteCategory
        :data="categoryOptions"
        v-model:categoryVisible="routeCategoryVisible"
        typeName="工艺"
        @onSelect="onChangeRouteCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="title"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="工艺路线编码"
                name="code"
                :rules="[
                    { required: true, message: '请输入工艺路线编码' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input
                    v-model:value="formData.code"
                    :maxlength="100"
                    showCount
                    :disabled="Boolean(upDateId)"
                />
            </a-form-item>

            <a-form-item
                label="工艺路线名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入工艺路线名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="版本号"
                name="version"
                :rules="[{ required: true, message: '请输入版本号' }, { validator: checkAllSpace }]"
            >
                <a-input v-model:value="formData.version" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="工艺类型"
                name="type"
                :rules="[{ required: true, message: '请选择工艺类型' }]"
            >
                <Select
                    v-model:value="formData.type"
                    :options="typeOptions"
                    :disabled="Boolean(copyId)"
                    labelKey="name"
                    valueKey="code"
                />
            </a-form-item>

            <a-form-item label="工艺类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :disabled="copyId ? 'disabled' : ''"
                        :onClick="() => (routeCategoryVisible = true)"
                        @onDelete="() => onChangeRouteCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item label="产品编码" name="productCode">
                <a-form-item>
                    <div class="contant-item">
                        <a-input
                            style="width: 330px"
                            v-model:value="formData.productCode"
                            :disabled="true"
                            :maxlength="100"
                            showCount
                        />
                        <a-button
                            type="primary"
                            :disabled="Boolean(copyId)"
                            @click="() => (productListVisible = true)"
                        >
                            查询
                        </a-button>
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item label="产品名称" name="productName">
                <a-input
                    v-model:value="formData.productName"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item label="规格型号" name="productSpec">
                <a-input
                    v-model:value="formData.productSpec"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item
                label="工艺路线描述"
                name="description"
                :rules="[{ validator: checkAllSpace }]"
                v-show="!copyId"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                    :disable="copyId"
                />
            </a-form-item>

            <a-form-item label="附件上传" name="bomFileIds">
                <UploadFile
                    businessType="BAS_PROCESS_BOM_FILE"
                    :files="formData.bomFileIds"
                    :disabled="Boolean(copyId)"
                    @onSuccess="fileInfo => onSuccess(fileInfo)"
                    @deleteFile="index => onDeleteFile(index)"
                ></UploadFile>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiRoutingUpdate, apiRoutingIndexData, apiRoutingCopy } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import UploadFile from "@/components/UploadFile";
import ProductList from "@/components/ProductList";
import RouteCategory from "@/components/CategoryModal";
import { fileURL } from "@/utils/config";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId", "copyId"],
    emits: ["eventUpdateOk", "update:upDateId", "update:copyId"],
    components: {
        Select,
        UploadFile,
        ProductList,
        RouteCategory,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            title: computed(() => {
                let title;
                if (props.copyId) {
                    title = "复制工艺路线";
                } else if (props.upDateId) {
                    title = "编辑工艺路线";
                } else {
                    title = "新增工艺路线";
                }
                return title;
            }),
            productListVisible: false,
            routeCategoryVisible: false,
            categoryOptions: [],
            typeOptions: [],
            formData: {
                id: "",
                name: "",
                code: "",
                type: "",
                category: "",
                categoryCode: "",
                categoryName: "",
                productCode: "",
                productName: "",
                productSpec: "",
                version: "",
                description: "",
                bomFileIds: [],
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
            emit("update:copyId", null);
        };

        const onFinish = async () => {
            let res;
            if (props.copyId) {
                let { id, name, code, version } = state.formData;
                res = await apiRoutingCopy({
                    id,
                    newCode: code,
                    newName: name,
                    newVersion: version,
                });
            } else {
                let bomFileIds = state.formData.bomFileIds.map(item => item?.id);
                res = await apiRoutingUpdate({
                    ...state.formData,
                    bomFileIds,
                });
            }
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiRoutingIndexData(id);
            if (res.status === "SUCCESS") {
                state.categoryOptions = res?.data?.categoryCandidate;
                state.typeOptions = res?.data?.typeCandidate;
                const { basProcess, bomFiles } = res?.data;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = basProcess ? basProcess[key] : "";
                });
                state.formData.bomFileIds = bomFiles || [];
                state.formData.category = {
                    key: basProcess?.categoryCode,
                    value: basProcess?.categoryName,
                };
                if (props.copyId) {
                    state.formData.code = "";
                }
            }
        };

        const onSelectProduct = ([product]) => {
            state.formData.productCode = product?.code;
            state.formData.productName = product?.name;
            state.formData.productSpec = product?.spec;
        };

        const onChangeRouteCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSuccess = fileInfo => {
            state.formData.bomFileIds = [...state.formData.bomFileIds, fileInfo];
        };

        const onDeleteFile = index => {
            state.formData.bomFileIds.splice(index, 1);
        };

        watch([() => props.upDateId, () => props.copyId], newVal => {
            let id = newVal.find(item => item != null);
            if (id != undefined) {
                state.visible = true;
                getIndexData(id);
            } else {
                state.visible = false;
            }
        });

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onSelectProduct,
            onChangeRouteCategory,
            onSuccess,
            onDeleteFile,
            fileURL,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
</style>
