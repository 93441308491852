<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <UpDateModal
            v-model:upDateId="upDateId"
            v-model:copyId="copyId"
            @eventUpdateOk="getTabelData"
        />
        <FilterBlock @search="search" />
        <SetRoutingModal v-model:setId="setId" @success="getTabelData" />
        <UploadData
            uploadUrl="/web/bas-process/import-data"
            downloadUrl="/download-process-import-template"
            tips="如果工艺路线已存在则进行更新, 对于工艺路线的工序先全部删除再进行添加"
            v-model:visible="showUploadDataModal"
            @uploadSuccess="getTabelData"
        />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1500 }"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                            <a @click="setId = record.id" v-perm="'btn:process-edit'">配置路线</a>
                            <a @click="copyId = record.id" v-perm="'btn:process-add'">复制</a>
                            <a @click="upDateId = record.id" v-perm="'btn:process-edit'">编辑</a>
                            <a @click="deleteRow(record.id)" v-perm="'btn:process-delete'">删除</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-space direction="horizontal" :size="8">
                            <a-button
                                type="primary"
                                @click="upDateId = ''"
                                v-perm="'btn:process-add'"
                            >
                                新增
                            </a-button>
                            <a-button @click="showUploadDataModal = true">导入</a-button>
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Detail from "./detail";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import SetRoutingModal from "./setRoutingModal";
import { confirmModal, showMessage } from "@/utils/common";
import { apiRoutingList, apiRoutingEnable, apiRoutingDelete } from "@/api";
import UploadData from "@/components/UploadData";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        SetRoutingModal,
        UploadData,
        Detail,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "工艺路线编码",
                dataIndex: "code",
            },
            {
                title: "工艺路线名称",
                dataIndex: "name",
            },
            {
                title: "版本号",
                dataIndex: "version",
                width: 200,
            },
            {
                title: "工艺类型",
                dataIndex: "typeDesc",
                width: 200,
            },
            {
                title: "工序数量",
                dataIndex: "stepCount",
                width: 150,
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "规格型号",
                dataIndex: "productSpec",
            },
            {
                title: "启用状态",
                dataIndex: "enabled",
                key: "enabled",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 220,
            },
        ];

        const state = reactive({
            showUploadDataModal: false,
            upDateId: null,
            detailId: null,
            copyId: null,
            setId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiRoutingList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "工艺路线禁用后，之后将无法在业务流程中使用该工艺路线信息，是否确认禁用？"
                : "工艺路线启用后，该工艺路线可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiRoutingEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "工艺路线删除后将无法恢复，是否确认删除该工艺路线？",
                onOk: async () => {
                    let res = await apiRoutingDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        return {
            columns,
            ...toRefs(state),
            changeSwitch,
            deleteRow,
            search,
            paginationChange,
            getTabelData,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
.desc {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid;
}
.desc::before {
    content: "";
    display: inline-block;
    width: 100px;
    height: 100px;
    position: absolute;
    right: -100px;
    border-left: 50px solid skyblue;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
}
</style>
