<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="工艺路线详情"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="工艺路线信息">
            <a-descriptions-item label="工艺路线编码">{{ infoData?.code }}</a-descriptions-item>
            <a-descriptions-item label="工艺路线名称">{{ infoData?.name }}</a-descriptions-item>
            <a-descriptions-item label="版本号">{{ infoData?.version }}</a-descriptions-item>
            <a-descriptions-item label="工艺类型">{{ infoData?.typeDesc }}</a-descriptions-item>
            <a-descriptions-item label="工艺类别">{{ infoData?.categoryName }}</a-descriptions-item>
            <a-descriptions-item label="产品编码">{{ infoData?.productCode }}</a-descriptions-item>
            <a-descriptions-item label="产品名称">{{ infoData?.productName }}</a-descriptions-item>
            <a-descriptions-item label="规格型号">{{ infoData?.productSpec }}</a-descriptions-item>
            <a-descriptions-item label="工艺路线附件">
                <div class="filebox">
                    <a v-for="item in infoData?.bomFiles" :href="`${fileURL}/download/${item?.id}`">
                        {{ item?.fileName }}
                    </a>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="工艺路线描述">
                {{ infoData?.description }}
            </a-descriptions-item>
        </a-descriptions>
        <div class="desc"></div>
        <a-descriptions title="工序列表"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="tableData.sort((a, b) => a.index - b.index)"
            bordered
            size="small"
            :loading="loading"
            :pagination="false"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'needDetect'">
                    {{ record?.needDetect ? "是" : "否" }}
                </template>
                <template v-if="column.dataIndex === 'stepFileIds'">
                    <div class="filebox">
                        <a
                            v-for="item in record?.stepFiles"
                            :href="`${fileURL}/download/${item?.id}`"
                        >
                            {{ item?.fileName }}
                        </a>
                    </div>
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import { apiRoutingDetail } from "@/api";
import { fileURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序名称",
        dataIndex: "name",
    },
    {
        title: "工序编码",
        dataIndex: "code",
    },
    {
        title: "工序类型",
        dataIndex: "typeDesc",
    },
    {
        title: "工序类别",
        dataIndex: "categoryName",
    },
    {
        title: "作业类型",
        dataIndex: "workModeZh",
    },
    {
        title: "质检要求",
        dataIndex: "needDetect",
    },
    {
        title: "工序附件",
        dataIndex: "stepFileIds",
    },
];

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId", "success"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            infoData: null,
            tableData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getSetIndexData = async id => {
            let res = await apiRoutingDetail(id);
            if (res.status === "SUCCESS") {
                state.infoData = res?.data;
                state.tableData = res?.data?.workSteps.map((item, index) => ({
                    index,
                    ...item,
                    ...item.workStepInfo,
                }));
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getSetIndexData(newVal);
                    state.savePermList = [];
                }
            }
        );

        return {
            fileURL,
            columns,
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
