<template>
    <UnitList v-model:unitListVisible="unitListVisible" @select="onSelectUnit" />
    <AddProcessModal
        v-model:addProcessvisible="addProcessvisible"
        :processType="routeInfo?.type"
        @select="onSelectedProcess"
    />
    <DeviceSelect
        v-model:deviceSelectVisible="deviceSelectVisible"
        :disableData="currentDevices"
        @onSelect="onSelectDevice"
    />
    <CheckPlan
        v-model:planVisible="planVisible"
        selectType="radio"
        type="PROCESS_CHECK"
        :allowItemsNone="false"
        @onSelect="onSelectPlan"
    />
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        :destroyOnClose="true"
        title="工艺路线配置"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <div class="border">
            <a-descriptions title="工艺路线信息" :column="2">
                <a-descriptions-item label="工艺路线编码">
                    {{ routeInfo?.code }}
                </a-descriptions-item>
                <a-descriptions-item label="版本号">{{ routeInfo?.version }}</a-descriptions-item>
                <a-descriptions-item label="工艺路线名称">
                    {{ routeInfo?.name }}
                </a-descriptions-item>
                <a-descriptions-item label="工艺类型">
                    {{ routeInfo?.typeDesc }}
                </a-descriptions-item>
                <a-descriptions-item label="附件">
                    <div class="filebox">
                        <a
                            v-for="item in routeInfo?.bomFiles"
                            :href="`${fileURL}/download/${item?.id}`"
                        >
                            {{ item?.fileName }}
                        </a>
                    </div>
                </a-descriptions-item>
            </a-descriptions>
        </div>
        <div class="border">
            <div class="header">
                <a-descriptions title="工艺路线配置信息"></a-descriptions>
                <a-space direction="horizontal" :size="8">
                    <a-button @click="() => (stepsData = [])">重置</a-button>
                    <a-button type="primary" @click="() => openAddProcessModal(true)">
                        添加
                    </a-button>
                </a-space>
            </div>
            <div class="process-box">
                <template v-for="(item, index) in stepsData" :key="index">
                    <div class="process-item">
                        <svg height="80" width="140">
                            <polygon
                                @click="() => (currentStepIndex = index)"
                                points="2,2 100,2 140,40 100,78 2,78"
                                :style="{
                                    fill: currentStepIndex == index ? '#80808033' : '#fff',
                                    stroke: 'black',
                                    strokeWidth: 1,
                                }"
                            />
                        </svg>
                        <div class="desc" @click="() => (currentStepIndex = index)">
                            <close-circle-outlined
                                class="close"
                                @click="e => handleDelete(index, e)"
                            />
                            <div>{{ item?.step?.name }}</div>
                            <div>
                                <caret-left-outlined
                                    class="arrow"
                                    :style="{ color: index == 0 ? 'gray' : 'black' }"
                                    @click="e => handleUp(index, e)"
                                />
                                <caret-right-outlined
                                    class="arrow"
                                    :style="{
                                        color: index == stepsData.length - 1 ? 'gray' : 'black',
                                    }"
                                    @click="e => handleDown(index, e)"
                                />
                            </div>
                        </div>
                        <plus-circle-outlined
                            class="arrow add"
                            v-if="index != stepsData.length - 1"
                        />
                    </div>
                </template>
            </div>

            <a-tabs>
                <a-tab-pane key="1" tab="工序信息">
                    <a-descriptions title="" :column="4">
                        <a-descriptions-item label="工序编码">
                            {{ currentStep?.code }}
                        </a-descriptions-item>
                        <a-descriptions-item label="工序名称">
                            {{ currentStep?.name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="工序类型">
                            {{ currentStep?.typeDesc }}
                        </a-descriptions-item>
                        <a-descriptions-item label="工序类别">
                            {{ currentStep?.categoryName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="作业类型">
                            {{ currentStep?.workModeZh }}
                        </a-descriptions-item>
                        <a-descriptions-item label="检验要求">
                            <span v-show="currentStep?.needDetect != undefined">
                                {{ currentStep?.needDetect ? "是" : "否" }}
                            </span>
                        </a-descriptions-item>
                        <a-descriptions-item label="标准工时">
                            {{ currentStep?.standardWorkDuration }} / 小时
                        </a-descriptions-item>
                        <a-descriptions-item label="标准产能">
                            {{ currentStep?.standardCapacity }}
                        </a-descriptions-item>
                        <a-descriptions-item label="人员配置">
                            {{ currentStep?.staffing }}
                        </a-descriptions-item>
                        <a-descriptions-item label="工序附件">
                            <div class="filebox">
                                <a
                                    v-for="item in currentStep?.stepFiles"
                                    :href="`${fileURL}/download/${item?.id}`"
                                >
                                    {{ item?.fileName }}
                                </a>
                            </div>
                        </a-descriptions-item>
                    </a-descriptions>
                </a-tab-pane>
                <a-tab-pane key="2" tab="生产单元信息">
                    <div class="unit-info">
                        <div class="search">
                            <a-button
                                type="primary"
                                size="small"
                                @click="unitListVisible = true"
                                class="search-btn"
                                v-show="stepsData.length"
                            >
                                查询
                            </a-button>
                        </div>
                        <a-descriptions title="" :column="4">
                            <a-descriptions-item label="生产单元编码">
                                {{ currentUnit?.code }}
                            </a-descriptions-item>
                            <a-descriptions-item label="生产单元名称">
                                {{ currentUnit?.name }}
                            </a-descriptions-item>
                            <a-descriptions-item label="生产单元类别">
                                {{ currentUnit?.categoryName }}
                            </a-descriptions-item>
                            <a-descriptions-item label="父级生产单元">
                                {{ currentUnit?.parentName }}
                            </a-descriptions-item>
                            <a-descriptions-item label="负责人">
                                {{ currentUnit?.principalDisplayName }}
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="设备列表">
                    <div class="search">
                        <a-button
                            type="primary"
                            size="small"
                            @click="deviceSelectVisible = true"
                            v-show="stepsData.length"
                        >
                            添加
                        </a-button>
                    </div>
                    <DeviceList
                        :deviceList="currentDevices"
                        @onDeleteDevice="index => onDeleteDevice(index)"
                    />
                </a-tab-pane>

                <a-tab-pane key="4" tab="质检方案" v-if="currentStep?.needDetect">
                    <div class="search">
                        <a-button
                            type="primary"
                            size="small"
                            @click="planVisible = true"
                            v-show="stepsData.length"
                        >
                            添加
                        </a-button>
                    </div>
                    <a-descriptions title="" :column="3">
                        <a-descriptions-item label="质检方案编码">
                            {{ currentPlan?.code }}
                        </a-descriptions-item>
                        <a-descriptions-item lastepGuardbel="质检方案名称">
                            {{ currentPlan?.name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="质检方案类别">
                            {{ currentPlan?.categoryName }}
                        </a-descriptions-item>
                    </a-descriptions>
                    <CheckItemList
                        :list="currentCheckItemList"
                        @onChangeStepGuard="index => onChangeStepGuard(index)"
                    />
                </a-tab-pane>
            </a-tabs>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiRoutingSetData, apiRoutingSaveSet } from "@/api";
import AddProcessModal from "./AddProcessModal";
import DeviceList from "./deviceList";
import CheckItemList from "./checkItemList";
import UnitList from "./unitList";
import DeviceSelect from "@/components/DeviceList";
import CheckPlan from "@/components/CheckPlan";
import { showMessage } from "@/utils/common";
import { fileURL } from "@/utils/config";
import { apiCheckPlanDetail, apiCheckPlanSet } from "@/api/qualityCheck";
import {
    CloseCircleOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
    props: ["setId"],
    emits: ["update:setId", "success"],
    components: {
        AddProcessModal,
        CaretLeftOutlined,
        CloseCircleOutlined,
        CaretRightOutlined,
        PlusCircleOutlined,
        UnitList,
        DeviceList,
        CheckItemList,
        DeviceSelect,
        CheckPlan,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            unitListVisible: false,
            deviceSelectVisible: false,
            planVisible: false,
            loading: false,
            routeInfo: null,
            stepsData: [],
            addProcessvisible: false,
            currentStepIndex: 0,
            currentStep: computed(() => state.stepsData[state.currentStepIndex]?.step),
            currentUnit: computed(() => state.stepsData[state.currentStepIndex]?.unit),
            currentDevices: computed(() =>
                state.stepsData[state.currentStepIndex]?.devices?.map((item, index) => ({
                    ...item,
                    key: index + 1,
                    index,
                }))
            ),
            currentPlan: computed(() => state.stepsData[state.currentStepIndex]?.plan),
            currentCheckItemList: computed(
                () => state.stepsData[state.currentStepIndex]?.checkItemList
            ),
        });

        const handleCancel = () => {
            emit("update:setId");
        };

        const openAddProcessModal = visible => {
            state.addProcessvisible = visible;
        };

        const handleSubmit = async () => {
            let reqData = {
                processId: props.setId,
                workSteps: state.stepsData.map(item => ({
                    workStepId: item?.step?.id,
                    productionUnitCode: item?.unit?.code,
                    deviceCodes: item?.devices?.map(uu => uu.code),
                    qcPlanId: item?.plan?.id,
                    needDetect: item?.step?.needDetect,
                    workStepGuardInfos: item.checkItemList?.map(uu => ({
                        basQcPlanItemId: uu.id,
                        stepGuard: uu.stepGuard,
                    })),
                })),
            };
            for (let item of reqData?.workSteps) {
                if (item.needDetect && !item.workStepGuardInfos) {
                    return showMessage("info", "请给需要质检的工序添加质检方案");
                }
            }
            let res = await apiRoutingSaveSet(reqData);
            if (res.status === "SUCCESS") {
                emit("update:setId", null);
                emit("success");
                showMessage("success", "保存成功");
            }
        };

        const changeProductionUnitCode = (e, index) => {
            if (state.stepsData[index].productionUnit) {
                state.stepsData[index].productionUnit.code = e.target.value;
            } else {
                state.stepsData[index].productionUnit = {
                    code: e.target.value,
                };
            }
        };

        const handleUp = (index, e) => {
            e.stopPropagation();
            if (index == 0) return showMessage("info", "已经是第一个");
            [state.stepsData[index], state.stepsData[index - 1]] = [
                state.stepsData[index - 1],
                state.stepsData[index],
            ];
        };

        const handleDown = (index, e) => {
            e.stopPropagation();
            if (index == state.stepsData.length - 1) return showMessage("info", "已经是最后一个");
            [state.stepsData[index], state.stepsData[index + 1]] = [
                state.stepsData[index + 1],
                state.stepsData[index],
            ];
        };

        const handleChange = index => {
            state.addProcessvisible = index + 1;
        };

        const handleDelete = (index, e) => {
            e.stopPropagation();
            state.stepsData.splice(index, 1);
        };

        const getSetIndexData = async roleId => {
            let res = await apiRoutingSetData(roleId);
            if (res.status === "SUCCESS") {
                state.routeInfo = res?.data;
                state.stepsData = res?.data?.workSteps.map((item, index) => ({
                    index,
                    step: item.workStepInfo,
                    unit: item.productionUnit,
                    devices: item.devices || [],
                    plan: item?.qcPlan ? item?.qcPlan : [],
                    checkItemList: item?.qcPlan?.items?.map(uu => ({
                        ...uu?.basQcItem,
                        ...uu,
                    })),
                }));
            }
        };

        const onSelectedProcess = (rows, index) => {
            if (typeof index !== "boolean") {
                state.stepsData[index - 1] = {
                    ...rows[0],
                    index: index - 1,
                    devices: [],
                    plan: null,
                };
            } else {
                state.stepsData = [
                    ...state.stepsData,
                    ...rows.map((item, index) => ({
                        index: state.stepsData.length + index,
                        step: item,
                        devices: [],
                        plan: null,
                    })),
                ];
            }
        };

        const onSelectUnit = ([unit]) => {
            if (state.stepsData[state.currentStepIndex]) {
                state.stepsData[state.currentStepIndex].unit = unit;
            }
        };

        const onDeleteDevice = index => {
            const devices = state.stepsData[state.currentStepIndex].devices;
            devices.splice(index, 1);
            state.stepsData[state.currentStepIndex].devices = [...devices].map((item, index) => ({
                ...item,
                key: index + 1,
                index,
            }));
        };

        const onSelectDevice = value => {
            if (state.stepsData[state.currentStepIndex]) {
                const devices = state.stepsData[state.currentStepIndex].devices;
                state.stepsData[state.currentStepIndex].devices = [...devices, ...value].map(
                    (item, index) => ({
                        ...item,
                        key: index + 1,
                        index,
                    })
                );
            }
        };

        const getCheckItems = async planId => {
            let res = await apiCheckPlanDetail(planId);
            if (res.status === "SUCCESS") {
                const { editInfo } = res?.data;
                state.stepsData[state.currentStepIndex].checkItemList = editInfo
                    ? editInfo?.items?.map((item, index) => ({
                          index,
                          baseItemId: item.itemId,
                          ...item.basQcItem,
                          ...item,
                          operationMethod: item?.operationMethod,
                          resultList:
                              item.testingDetails.map((item, index) => ({
                                  ...item,
                                  key: index + 1,
                                  index,
                              })) || [],
                          toolList: item.checkTools || [],
                          stepGuard: false,
                      }))
                    : [];
            } else {
                state.stepsData[state.currentStepIndex].checkItemList = [];
            }
        };

        const onSelectPlan = value => {
            state.stepsData[state.currentStepIndex].plan = value[0];
            getCheckItems(value[0]?.id);
        };

        const onChangeStepGuard = index => {
            const { stepGuard } = state.stepsData[state.currentStepIndex].checkItemList[index];
            state.stepsData[state.currentStepIndex].checkItemList[index].stepGuard = !stepGuard;
        };

        watch(
            () => props.setId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getSetIndexData(newVal);
                    state.savePermList = [];
                }
            }
        );

        return {
            fileURL,
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            handleUp,
            handleChange,
            handleDelete,
            handleDown,
            openAddProcessModal,
            onSelectedProcess,
            changeProductionUnitCode,
            onSelectUnit,
            onSelectDevice,
            onDeleteDevice,
            onChangeStepGuard,
            onSelectPlan,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.border {
    border: 1px solid;
    padding: 20px;
    margin-bottom: 20px;
    .header {
        display: flex;
        justify-content: space-between;
    }
}
.process-box {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px;
    background: #80808017;
    height: 100px;
    .process-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .desc {
            width: 125px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 5px;
            box-sizing: border-box;
            font-size: 13px;
            .close {
                position: absolute;
                top: 8px;
                right: 20px;
                cursor: pointer;
                font-size: 13px;
            }
            .arrow {
                cursor: pointer;
                margin-top: 5px;
                font-size: 15px;
            }
        }
    }
    .add {
        font-size: 18px;
        margin: 0 10px;
    }
}
.unit-info {
    position: relative;
    .search-btn {
        margin-bottom: 10px;
    }
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
.search {
    text-align: right;
    margin-bottom: 10px;
}
</style>
