<template>
    <a-modal
        v-model:visible="visible"
        title="数据字典-生产单元"
        width="1200px"
        okText="提交"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <UnitList @select="onSelect" />
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { showMessage } from "@/utils/common";
import UnitList from "@/components/UnitList";

export default defineComponent({
    props: {
        unitListVisible: {
            default: false,
        },
        data: {
            default: [],
        },
        typeName: {
            default: "",
        },
    },
    components: {
        UnitList,
    },
    emits: ["update:unitListVisible", "onSelect"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            selectedRows: [],
        });

        const handleCancel = () => {
            state.visible = false;
            state.selectedRows = [];
            emit("update:unitListVisible", false);
        };

        const onSelect = selectedRows => {
            state.selectedRows = selectedRows;
        };

        const handleSubmit = async () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择生产单元");
            }
            emit("select", state.selectedRows);
            handleCancel();
        };

        watch(
            () => props.unitListVisible,
            newVal => {
                state.visible = Boolean(newVal);
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            onSelect,
        };
    },
});
</script>

<style lang="less" scoped>

</style>
