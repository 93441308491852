<template>
    <a-table :columns="columns" :data-source="list" bordered size="small" :pagination="false">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'resultList'">
                <div>
                    <a-table
                        style="margin: 0"
                        :dataSource="record.testingDetails"
                        size="small"
                        :pagination="false"
                        :columns="resultListColumns"
                    />
                </div>
            </template>
            <template v-if="column.dataIndex === 'standardWorkingHours'">
                {{ record?.standardWorkingHours?.workingHours }}
                {{ record?.standardWorkingHours?.unit }}
            </template>
            <template v-if="column.dataIndex === 'stepGuard'">
                <a-switch :checked="record.stepGuard" @change="enabled => changeSwitch(index)" />
            </template>
        </template>
    </a-table>
</template>
<script>
export default {
    props: {
        list: {
            default: [],
        },
    },
    emits: ["onDeletePlan"],
    setup(props, { emit }) {
        const changeSwitch = index => {
            emit("onChangeStepGuard", index);
        };

        return {
            columns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    width: 60,
                },
                {
                    title: "质检项编码",
                    dataIndex: "code",
                },
                {
                    title: "质检项名称",
                    dataIndex: "name",
                },
                {
                    title: "质检项属性",
                    dataIndex: "typeZh",
                },
                {
                    title: "检验标准",
                    dataIndex: "testingStandards",
                },
                {
                    title: "标准工时",
                    dataIndex: "standardWorkingHours",
                },
                {
                    title: "结果值列表",
                    dataIndex: "resultList",
                },
                // {
                //     title: "工序卡控",
                //     dataIndex: "stepGuard",
                //     width: 130,
                // },
            ],
            resultListColumns: [
                {
                    title: "名称",
                    dataIndex: "label",
                },
                {
                    title: "标准值",
                    dataIndex: "standardValue",
                },
                {
                    title: "上限",
                    dataIndex: "upperLimit",
                },
                {
                    title: "下限",
                    dataIndex: "lowerLimit",
                },
                {
                    title: "单位",
                    dataIndex: "unit",
                },
            ],
            changeSwitch,
        };
    },
};
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
</style>
